<template>
	<div>
		<div class="container">
			<div class="header">
				<a @click="$router.back(-1)"> <img src="../assets/images/back.png" alt=""></a> 收货地址</div>
			<div class="blian22"></div>
		</div>
		<div class="address-main">
			<div class="address-infor" v-for="(item,index) in userAddrs" :key="index" @click="giveAddressInfo(item)">
				<div class="address-name">
					<span>{{ item.recName }}</span>
					<span>{{ item.recPhone }}</span>
					<img src="../assets/images/delete.png" alt="" @click="delUserAddr(item.addrId)">
					<img src="../assets/images/edit.png" alt="" @click="editAddress(item)" style="padding-right: 0.4rem;">
				</div>
				<p>{{ item.province }}{{ item.city }}{{ item.region }}{{ item.detail }}</p>
			</div>
		</div>

		<div class="blian52"></div>
		<!--底部按钮 begin-->
		<div class="cont_foot_btn">
			<router-link to="/myaddaddress">添加收货地址</router-link>
		</div>
		<!--底部按钮 end-->
	</div>
</template>
<script>
	import { userAddrs, delUserAddr } from "@/assets/js/api";
	import { MessageBox, Toast } from 'mint-ui';
	export default {
		name: "myaddress",
		data() {
			return {
				userAddrs: [],
			}
		},
		methods: {
			giveAddressInfo(info){
				if(this.$route.query.type==1){
					this.$router.replace({
						path: '/myrecordedit',
						query: {
							info: JSON.stringify(info)
						}
				})
				}
			},
			
			//获取地址列表
			getuserAddrs() {
				userAddrs({}).then(res => {
					if(res.code == 0) {
						this.userAddrs = res.userAddrs;
					}
				});
			},
			//编辑
			editAddress(info) {
				this.$router.push({
						path: '/editAddress',
						query: {
							info: JSON.stringify(info)
						}
				})
			},
			//删除收货地址
			delUserAddr(addrId) {
				MessageBox.confirm('是否删除地址?').then(action => {
					delUserAddr({
						addrId: addrId
					}).then(res => {
						if(res.code == 0) {
							Toast({
								message: res.msg,
								position: 'middle',
								duration: 1000
							});
							this.getuserAddrs()
						}
					});
				});
			}
		},
		mounted() {
			this.getuserAddrs();
			 console.log(this.$route.query.type)

		}
	};
</script>